<template>
    <div class="container">
        <div class="px-3 py-3 pt-lg-5 pb-lg-4 mx-auto text-center">
            <div class="sec-head font-weight-bolder">Case List</div>
        </div>
        <div class="col-lg-4 w-50">
            <div class="row">
                <input class="form-control col" type="text" v-model="searchname" v-on:keyup.enter="searchcase" placeholder="Search by Name" aria-label="Search">
                <button class="btn btn-md ml-2 col" @click="cleansearch">Clean</button>
            </div>
        </div>
        <div class="px-3 py-3 pb-lg-4 text-center">
            <table class="table table-borderless">
                <thead>
                    <th scope="col">
                        Order Id
                    </th>
                    <th scope="col">
                        Start Date(mm-dd-yyyy)
                    </th>
                    <th scope="col">
                        Business Name
                    </th>
                    <th scope="col">
                        Client Name
                    </th>
                    <th scope="col">
                        Action
                    </th>
                </thead>
                <tbody>
                    <tr v-for="(item, key) in cases = searching ? searchlist : mycaselist" :key="key">
                        <td>{{cases[key].orderId}}</td>
                        <td>{{casetime(cases[key])}}</td>
                        <td>{{cases[key].businessName}}</td>
                        <td>{{cases[key].clientName}}</td>
                        <td>
                            <a @click="onDetail(cases[key].orderId)" href="javascript:;" class="btn btn-small btn-primary btn-detail" id="caselist-detail">Details</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
  import gql from 'graphql-tag'

  export default {
    data () {
        return{
              searchname: '',
              searchlist: [],
              searching: false,
              casetoken: ''
          }
    },
    apollo: {
        caselist(){
                return{
                    query: gql `query caselist($caseToken: String!) {
                        caselist: cases(caseToken: $caseToken) {
                            orderId
                            casestartDate
                            businessName
                            clientName
                        }
                    }`,
                    variables() {
                        return {
                            caseToken: this.casetoken,
                        }
                    }
                }
            }
    },
    created () {
        if (this.$route.query.token !== undefined) {
            localStorage.token = this.$route.query.token;
        }
        if (localStorage.token) {
            this.casetoken = localStorage.token
        }
    },
    computed: {
        mycaselist() {
            if(this.caselist){
                var templist = this.caselist
                templist.sort(function(a, b){return b.casestartDate-a.casestartDate})
                return templist
            }
            return []
        }
    },
    methods: {
        onDetail(id) {
            window.open(`/order-review?orderId=${id}`)
        },
        cleansearch() {
            this.searchname = ''
            this.searching = false
        },
        searchcase() {
            let templist = []
            if(this.mycaselist){
                for(var i = 0; i<this.mycaselist.length; i = i+1){
                    let tempname = this.mycaselist[i].clientName.toLowerCase()
                    let tempbusi = this.mycaselist[i].businessName.toLowerCase()
                    if(tempname.includes(this.searchname.toLowerCase()) || tempbusi.includes(this.searchname.toLowerCase())){
                        templist.push(this.mycaselist[i])
                    }
                }
            }
            this.searchlist = templist
            this.searching = true
        },
        casetime(key) {
            if(key){
                if(key.casestartDate == 0){
                    return 'Not recorded yet'
                }
                let mytime = new Date(parseInt(key.casestartDate))
                let myyear = mytime.getFullYear()
                let mymonth = mytime.getMonth() + 1
                let mydate = mytime.getDate()
                if(mymonth < 10){
                    mymonth = '0' + mymonth
                }
                return mymonth + '-' + mydate + '-' + myyear
            }
            return 'Not recorded yet'
        }
    }
  }
</script>

<style scoped lang="scss">
    .yellow {
        color: rgba(242, 204, 79, 1.0);
    }

    .yellowBtn {
        color: rgba(54, 77, 110, 1.0);
        background-color: rgba(242, 204, 79, 1.0);
        border: none;
    }
    .row {
        margin: 20px auto;
    }

    td {
        vertical-align: initial;
    }
</style>